import { Container, Row, Col } from 'react-bootstrap';
// import { MailchimpForm } from "./MailchimpForm";
// import logo from '../assets/img/logo.svg';
import logo2 from '../assets/img/logo2.svg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
// import navIcon3 from '../assets/img/nav-icon3.svg';
import navIcon4 from '../assets/img/nav-icon4.svg';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            <img src={logo2} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon my-2">
              <a href="https://www.linkedin.com/in/al-shaimon/" target="_blank">
                <img src={navIcon1} alt="Icon" />
              </a>
              <a href="https://www.facebook.com/al.shaimon" target="_blank">
                <img src={navIcon2} alt="Icon" />
              </a>
              <a href="https://github.com/al-shaimon">
                <img src={navIcon4} alt="Icon" />
              </a>
            </div>
            <p>Copyright 2022. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
